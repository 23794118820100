<template>
  <div id="table-management" class="site-container">
    <div class="general-datatable-wp">
      <v-row no-gutters>
        <v-col cols="12" class="general-datatable-content">
          <v-data-table
            :headers="headers"
            :items="dataForm"
            :sort-by="['start_date']"
            :sort-desc="[true]"
            no-data-text="No Data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @pagination="updatePagination"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            :mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-row no-gutters id="table-search">
                <v-col cols="12" md="12">
                  <div class="total-heading total-heading-left" style="visibility: hidden">
                    <h1>Tariff Management</h1>
                  </div>
                  <div class="filters-board">
                    <v-col cols="4">
                      <v-row no-gutters class="filter-group">
                        <v-col cols="12" md="4" class="modal-label">
                          <span>Global:</span>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-select
                            ref="siteGlobalSelect"
                            :items="listGlobals"
                            v-model="global"
                            item-text="name"
                            item-value="id"
                            :class="`${global ? 'custom-hidden-input' : ''}`"
                            class="custom-select__selections"
                            required
                            filled
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                            append-icon="fas fa-caret-down"
                            placeholder="Select Global"
                            @change="handleChangeGlobal(global)"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="filter-group pt-2">
                        <v-col cols="12" md="4" class="modal-label">
                          <span>Regional:</span>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-select
                            ref="siteRegionalSelect"
                            :items="listRegionals"
                            v-model="regional"
                            item-text="name"
                            item-value="id"
                            :class="`${regional ? 'custom-hidden-input' : ''}`"
                            class="custom-select__selections"
                            required
                            filled
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                            append-icon="fas fa-caret-down"
                            placeholder="Select Regional"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 + (currentPage - 1) * itemsPerPage }}
            </template>
            <template v-slot:[`item.start_date`]="{ item }">
              <td class="text-center">
                {{ item.start_date ? MOMENT(item.start_date, 'DD-MM-YYYY') : "−−" }}
              </td>
            </template>
            <template v-slot:[`item.end_date`]="{ item }">
              <td class="text-center">
                {{ item.end_date ? MOMENT(item.end_date, 'DD-MM-YYYY') : "permanent" }}
              </td>
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
              <td class="text-center">
                {{ item.updated_at ? MOMENT(item.updated_at, 'DD-MM-YYYY') : "−−" }}
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="isDateCurrentOrFuture(item.end_date, 'DD-MM-YYYY') || item.end_date === 'permanent'">
                <div class="d-flex justify-content-center">
                  <v-btn
                    :ripple="false"
                    icon
                    title="Edit Tariff"
                  >
                    <img 
                      class="icon-action" 
                      src="@/assets/tables/asset_edit.svg" 
                      @click="openDialogEdit(item)" />
                  </v-btn>
                  <v-btn
                    :ripple="false"
                    icon
                    title="Delete Tariff"
                    @click="openConfirmDialog(item, deleteTariff, 'Are you sure?', 'You’re about to delete this tariff. This action cannot be undone. Still proceed?')"
                  >
                    <img 
                      class="icon-action" 
                      src="@/assets/tables/asset_delete.svg" 
                    />
                  </v-btn>
                </div>
              </template>
            </template>
          </v-data-table>
          <div class="data-table-paging flex-footer">
            <button class="dialog-default-button btn_UserManagement data-table-add-size dialog-bg-grey-button switch-btn add-user-btn button-hover " @click="openDialogAddTariff">
              Add New Tariff
            </button>
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              :dark="true"
            ></v-pagination>
          </div>
        </v-col>
        <v-col cols="12" md="0" sm="0" />
      </v-row>
      <v-dialog
        v-model="dialogAddTariff"
        persistent
        content-class="custom-content-modals modal-input-not-mb custom-scroll-modals"
        max-width="786"
      >
        <v-card>
          <v-card-title>Add New Tariff</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-form
              ref="addForm"
              @submit.prevent="confirmAdd"
              autocomplete="off"
            >
              <div class="scroll_dialog_form">
                <v-row no-gutters>
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Name</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="tariffName"
                      :rules="nameRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Name"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Start of Validity Period</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-menu
                      ref="menuStartDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      :nudge-top="20"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      content-class="custom_schedule_full_width"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDateText"
                          :rules="startDateRules"
                          append-icon="fas fa-calendar-alt"
                          placeholder="Select Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="date-picker-text-field date-picker-batch"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        :min="MIN_DATE().format(DATE_PICKER_FORMAT)"
                        :dark="true"
                        class="customize_commissioned_date_picker custom_picker_actions tariff__date-picker"
                        @change="changedStartDate"
                      >
                        <v-btn class="actions__btn-default actions__btn-outline" text 
                          @click="startDateText = ''; startDate = null"
                        >
                          Clear
                        </v-btn>
                        <v-btn class="actions__btn-default actions__btn-background" text 
                          @click="menuStartDate = false"
                        >
                          Confirm
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>End of Validity Period</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-menu
                      ref="menuEndDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      :nudge-top="20"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      content-class="custom_schedule_full_width"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDateText"
                          :rules="endDateRules"
                          append-icon="fas fa-calendar-alt"
                          placeholder="Select Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="date-picker-text-field date-picker-batch"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        no-title
                        :min="MIN_DATE().format(DATE_PICKER_FORMAT)"
                        :dark="true"
                        class="customize_commissioned_date_picker custom_picker_actions tariff__date-picker"
                        @change="changedEndDate"
                      >
                        <v-btn class="actions__btn-default actions__btn-outline" text 
                          @click="endDateText = ''; endDate = null"
                        >
                          Clear
                        </v-btn>
                        <v-btn class="actions__btn-default actions__btn-background" text 
                          @click="menuEndDate = false"
                        >
                          Confirm
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Currency</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      ref="currencySelect"
                      :items="currenciesList"
                      v-model="currency"
                      :rules="currencyRules"
                      :class="`${currency ? 'custom-hidden-input' : ''}`"
                      class="custom-select__selections"
                      required
                      filled
                      :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                      append-icon="fas fa-caret-down"
                      placeholder="Select Currency"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Flat Tariff</span>
                    <label class="symbol-required">*</label>
                    <span> (per kWh)</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="flatTariff"
                      :rules="flatTariffRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Flat Tariff"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>On-peak Tariff</span>
                    <label class="symbol-required">*</label>
                    <span> (per kWh)</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="onPeakTariff"
                      :rules="onPeakTariffRules"
                      required
                      autocomplete="off"
                      placeholder="Enter On-peak Tariff"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Off-peak Tariff</span>
                    <label class="symbol-required">*</label>
                    <span> (per kWh)</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="offPeakTariff"
                      :rules="offPeakTariffRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Off-peak Tariff"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12">
                    <span>* The price will be set as permanent if End of Validity Period is empty</span>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogAddTariff"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogEditTariff"
        persistent
        content-class="custom-content-modals modal-input-not-mb custom-scroll-modals"
        max-width="786"
      >
        <v-card>
          <v-card-title>Edit Tariff</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-form
              ref="editForm"
              @submit.prevent="handleSubmitEdit"
              autocomplete="off"
            >
              <div class="scroll_dialog_form">
                <v-row no-gutters>
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Name</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="editTariffName"
                      required
                      autocomplete="off"
                      :rules="nameRules"
                      placeholder="Enter Name"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Start of Validity Period</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-menu
                      ref="menuEditStartDate"
                      v-model="menuEditStartDate"
                      :close-on-content-click="false"
                      :nudge-top="20"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      content-class="custom_schedule_full_width"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editStartDateText"
                          :rules="editStartDateRules"
                          append-icon="fas fa-calendar-alt"
                          placeholder="Select Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="date-picker-text-field date-picker-batch"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editStartDate"
                        no-title
                        :min="MIN_DATE().format(DATE_PICKER_FORMAT)"
                        :dark="true"
                        class="customize_commissioned_date_picker custom_picker_actions tariff__date-picker"
                        @change="changedEditStartDate"
                      >
                        <v-btn class="actions__btn-default actions__btn-outline" text 
                          @click="editStartDateText = ''; editStartDate = null"
                        >
                          Clear
                        </v-btn>
                        <v-btn class="actions__btn-default actions__btn-background" text 
                          @click="menuEditStartDate = false"
                        >
                          Confirm
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>End of Validity Period</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-menu
                      ref="menuEditEndDate"
                      v-model="menuEditEndDate"
                      :close-on-content-click="false"
                      :nudge-top="20"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      content-class="custom_schedule_full_width"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editEndDateText"
                          :rules="editEndDateRules"
                          append-icon="fas fa-calendar-alt"
                          placeholder="Select Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="date-picker-text-field date-picker-batch"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editEndDate"
                        no-title
                        :min="MIN_DATE().format(DATE_PICKER_FORMAT)"
                        :dark="true"
                        class="customize_commissioned_date_picker custom_picker_actions tariff__date-picker"
                        @change="changedEditEndDate"
                      >
                        <v-btn class="actions__btn-default actions__btn-outline" text 
                          @click="editEndDateText = ''; editEndDate = null"
                        >
                          Clear
                        </v-btn>
                        <v-btn class="actions__btn-default actions__btn-background" text 
                          @click="menuEditEndDate = false"
                        >
                          Confirm
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Currency</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      ref="editCurrencySelect"
                      :items="currenciesList"
                      v-model="editCurrency"
                      :rules="currencyRules"
                      :class="`${editCurrency ? 'custom-hidden-input' : ''}`"
                      class="custom-select__selections"
                      required
                      filled
                      :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                      append-icon="fas fa-caret-down"
                      placeholder="Select Currency"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Flat Tariff</span>
                    <label class="symbol-required">*</label>
                    <span> (per kWh)</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="editFlatTariff"
                      :rules="flatTariffRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Flat Tariff"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>On-peak Tariff</span>
                    <label class="symbol-required">*</label>
                    <span> (per kWh)</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="editOnPeakTariff"
                      :rules="onPeakTariffRules"
                      required
                      autocomplete="off"
                      placeholder="Enter On-peak Tariff"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Off-peak Tariff</span>
                    <label class="symbol-required">*</label>
                    <span> (per kWh)</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="editOffPeakTariff"
                      :rules="offPeakTariffRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Off-peak Tariff"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogEdit"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmAddDialog"
        persistent
        max-width="672"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title class="justify-content-center">{{ confirmHeading }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="dialog-content">
                <p class="text-center"><span>{{ confirmContent }}</span></p>
                <div class="tariff-info text-center">
                  <p class="mb-2">
                    <span class="font-weight-bold">Effective period: </span>
                    <span>from {{ MOMENT(this.startDate, "DD/MM/YYYY") }} 
                      to {{ this.endDate ? MOMENT(this.endDate, "DD/MM/YYYY") : 'permanent' }}</span>
                  </p>
                  <p class="mb-2">
                    <span class="font-weight-bold">Flat rate: </span>
                    <span>{{ this.flatTariff }} per kWh</span>
                  </p>
                  <p class="mb-2">
                    <span class="font-weight-bold">On-peak rate: </span>
                    <span>{{ this.onPeakTariff }} per kWh</span>
                  </p>
                  <p class="mb-2">
                    <span class="font-weight-bold">Off-peak rate: </span>
                    <span>{{ this.offPeakTariff }} per kWh</span>
                  </p>
                </div>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-outline-button"
                  type="button"
                  :ripple="false"
                  @click="closeConfirmAddDialog"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="submitAdd"
                >
                  CONFIRM
                </button>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="672"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title>{{ dialogHeading }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="dialog-content">
                <span>{{ dialogMessage }}</span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-outline-button"
                  type="button"
                  :ripple="false"
                  @click="closeConfirmDialog"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="dialogAction(tariffItem)"
                >
                  CONFIRM
                </button>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="messageDialog"
        max-width="500"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title class="justify-content-center">{{ apiTitle }}</v-card-title>
          <v-card-text
            ><v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span v-html="apiMessage"></span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="closeMessage"
                >
                  OK
                </button>
              </v-col>
            </v-row></v-card-text
          >
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/general-datatable-page.scss"></style>
<script>
import { tariffService, multipleSitesService, } from "@/services";
import moment from "moment";
export default {
  data() {
    return {
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      headers: [
        { text: "No", value: "no", align: 'center', sortable: false },
        { text: "Tariff Name", value: "name", align: 'center', sortable: true },
        { text: "Flat (Per kWh)", value: "flat", align: 'center', sortable: false },
        { text: "On-peak (Per kWh)", value: "on_peak", align: 'center', sortable: false },
        { text: "Off-peak (Per kWh)", value: "off_peak", align: 'center', sortable: false },
        { text: "Currency", value: "currency", align: 'center', sortable: false },
        { text: "Start of Validity Period", value: "start_date", align: 'center', sortable: true },
        { text: "End of Validity Period", value: "end_date", align: 'center', sortable: true },
        { text: "Updated At", value: "updated_at", align: 'center', sortable: true },
        { text: "Action", value: "actions", sortable: false, align: 'center', filterable: false },
      ],
      dialogAddTariff: false,
      dialogEditTariff: false,
      confirmDialog: false,
      dialogAction: () => null,
      dialogHeading: "",
      dialogMessage: "",
      tariffItem: {},
      dataForm: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      currentPage: 1,
      listGlobals: [],
      global: "",
      listRegionals: [],
      regional: "",
      tariffName: "",
      menuStartDate: false,
      startDateText: "",
      startDate: null,
      menuEndDate: false,
      endDateText: "",
      endDate: null,
      currency: "THB",
      currenciesList: ["THB"],
      flatTariff: null,
      onPeakTariff: null,
      offPeakTariff: null,
      editTariffName: "",
      isConfirm: true,
      confirmAddDialog: false,
      confirmHeading: "",
      confirmContent: "",
      menuEditStartDate: false,
      editStartDateText: "",
      editStartDate: null,
      menuEditEndDate: false,
      editEndDateText: "",
      editEndDate: null,
      editCurrency: "",
      editFlatTariff: null,
      editOnPeakTariff: null,
      editOffPeakTariff: null,
      tariffInfo: null,
      nameRules: [
        (v) => !!v || "Tariff Name is required",
        (v) => (v && v.length <= 25) || "Tariff Name must be equal or less than 25 characters",
      ],
      currencyRules: [
        (v) =>!!v || "Currency is required",
      ],
      flatTariffRules: [
        (v) => v !== null && v !== undefined && v !== "" || "Flat rate is required",
        (v) => !isNaN(v) || "Flat rate must be a number"
      ],
      onPeakTariffRules: [
        (v) => v !== null && v !== undefined && v !== "" || "On-peak rate is required",
        (v) => !isNaN(v) || "On-peak rate must be a number"
      ],
      offPeakTariffRules: [
        (v) => v !== null && v !== undefined && v !== "" || "Off-peak rate is required",
        (v) => !isNaN(v) || "Off-peak rate must be a number"
      ],
      selectMenuList: ["currencySelect", "editCurrencySelect", "menuStartDate", "menuEndDate", "menuEditStartDate", "menuEditEndDate"],
    };
  },
  mounted() {
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('scroll', this.onScroll, true);
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('scroll', this.onScroll, true);
  },
  async created() {
    await this.getListGlobals();
    if (this.listGlobals?.length) {
      // HARD CODE
      this.listGlobals = [this.listGlobals.find(item => item.name === "Thailand") || this.listGlobals[0]];
      this.global = this.listGlobals[0]?.id;
    }
    this.handleChangeGlobal(this.global);
  },
  computed: {
    startDateRules () {
      return [
        (v) => {
          if (!v) {
            return "Start Date is required";
          }
          return true;
        },
        (v) => {
          if (this.endDate) {
            const start = moment(v, "DD/MM/YYYY").format(this.DATE_PICKER_FORMAT);
            const end = moment(this.endDate, "YYYY-MM-DD").format(this.DATE_PICKER_FORMAT);
            if (start > end) {
              return "Start Date must be earlier than or equal End Date";
            }
          }
          return true;
        }
      ];
    },
    editStartDateRules () {
      return [
        (v) => {
          if (!v) {
            return "Start Date is required";
          }
          return true;
        },
        (v) => {
          if (this.editEndDate) {
            const start = moment(v, "DD/MM/YYYY").format(this.DATE_PICKER_FORMAT);
            const end = moment(this.editEndDate, "YYYY-MM-DD").format(this.DATE_PICKER_FORMAT);
            if (start > end) {
              return "Start Date must be earlier than or equal End Date";
            }
          }
          return true;
        }
      ];
    },
    endDateRules () {
      return [
        (v) => {
          if (this.startDate) {
            const start = moment(this.startDate, "YYYY-MM-DD").format(this.DATE_PICKER_FORMAT);
            const end = moment(v, "DD/MM/YYYY").format(this.DATE_PICKER_FORMAT);
            if (start > end) {
              return "End Date must be later than or equal Start Date";
            }
          }
          return true;
        }
      ];
    },
    editEndDateRules () {
      return [
        (v) => {
          if (this.editStartDate) {
            const start = moment(this.editStartDate, "YYYY-MM-DD").format(this.DATE_PICKER_FORMAT);
            const end = moment(v, "DD/MM/YYYY").format(this.DATE_PICKER_FORMAT);
            if (start > end) {
              return "End Date must be later than or equal Start Date";
            }
          }
          return true;
        }
      ];
    },
  },
  methods: {
    updatePagination(pagination) {
      this.currentPage = pagination.page;
      this.itemsPerPage = pagination.itemsPerPage;
    },
    isDateCurrentOrFuture(date, format) {
      return moment(date, format).isSameOrAfter(moment(), 'day');
    },
    changedStartDate() {
      return this.startDateText = this.startDate ? moment(this.startDate).format("DD/MM/YYYY") : "";
    },
    changedEditStartDate() {
      return this.editStartDateText = this.editStartDate ? moment(this.editStartDate).format("DD/MM/YYYY") : "";
    },
    changedEndDate() {
      return this.endDateText = this.endDate ? moment(this.endDate).format("DD/MM/YYYY") : "";
    },
    changedEditEndDate() {
      return this.editEndDateText = this.editEndDate ? moment(this.editEndDate).format("DD/MM/YYYY") : "";
    },
    async getListGlobals() {
      return await multipleSitesService
        .getListGlobals()
        .then((res) => {
          if (res?.data?.data) {
            const dataSiteGlobals = res.data.data;
            this.listGlobals = [...dataSiteGlobals];
            return res;
          }
        })
        .catch(() => {
          return null;
        });
    },
    handleChangeGlobal(global_id) {
      this.listRegionals = [
        {
          "id": "all",
          "name": "ALL",
        }
      ];
      this.regional = "all";
      this.getListTariffs(global_id);
      // const selectedGlobal = this.listGlobals.find(global => global.id === global_id);
      // if (selectedGlobal) {
      //   this.listRegionals = [...this.listRegionals, ...selectedGlobal.regionals];
      // }
    },
    onMouseMove(event) {
      this.handleMouseMove(event, this);
    },
    onScroll() {
      this.handleScroll(this.selectMenuList, this);
    },
    getListTariffs(global_id) {
      if (global_id) {
        let data = { global_id: global_id };
        tariffService.getListTariffs(data).then((res) => {
          if(res?.data?.data) {
            let data = res.data.data;
            this.dataForm = [...data];
          }
        });
      }
    },
    deleteTariff(item) {
      if (item?.id) {
        tariffService
          .deleteTariff(item.id)
          .then((res) => {
            if (res.status !== 200 && res.status !== 204) throw res;
            this.closeConfirmDialog();
            this.apiMessage = "Tariff has been successfully deleted";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListTariffs(this.global);
          })
          .catch((err) => {
            let message = "Tariff cannot be deleted";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    async confirmAdd() {
      if (this.$refs.addForm.validate()) {
        const data = this.prepareData(
          this.global,
          this.tariffName,
          this.startDate,
          this.endDate,
          this.currency,
          this.flatTariff,
          this.onPeakTariff,
          this.offPeakTariff,
          this.isConfirm,
        );
        let heading = "Create New Tariff";
        let content = "You’re about to create a new tariff, change will be applied immediately.";
        if (this.endDate && !this.isDateCurrentOrFuture(this.endDate, 'YYYY-MM-DD')) {
          heading = "Warning!";
          content = "You’re about to create a tariff for past data. This change may take up to 60 minutes to be reflected. Once created, this tariff cannot be modified or deleted.";
        }
        await tariffService
          .storeTariff(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.openConfirmAddDialog(heading, content);
          })
          .catch((err) => {
            let message = "Something went wrong";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiTitle = "Error";
            if (message === "There is a conflict with existing periods. Please check again!") {
              this.apiTitle = "Invalid Validity Period";
            }
            this.apiMessage = message;
            this.messageDialog = true;
          });
      }
    },
    async submitAdd() {
      this.isConfirm = false;
      const data = this.prepareData(
        this.global,
        this.tariffName,
        this.startDate,
        this.endDate,
        this.currency,
        this.flatTariff,
        this.onPeakTariff,
        this.offPeakTariff,
        this.isConfirm,
      );
      let title = "Success";
      let message = `Tariff has been successfully added`;
      if (this.endDate && !this.isDateCurrentOrFuture(this.endDate, 'YYYY-MM-DD')) {
        title = "Tariff added successfully";
        message = `The Historical Expenses & Savings will be recalculated based on the new tariff and will update within 60 minutes.<br>This tariff cannot be modified or deleted.`;
      }
      await tariffService
        .storeTariff(data)
        .then((res) => {
          if (res.status !== 200) throw res;
          this.getListTariffs(this.global);
          this.closeConfirmAddDialog();
          this.closeDialogAddTariff();
          this.apiMessage = message;
          this.apiTitle = title;
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "Tariff cannot be added";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        });
      this.isConfirm = true;
    },
    revertTariff() {
      this.$refs.addForm.reset();
    },
    openDialogAddTariff() {
      this.flatTariff = 0;
      this.onPeakTariff = 0;
      this.offPeakTariff = 0;
      this.dialogAddTariff = true;
    },
    closeDialogAddTariff() {
      this.revertTariff();
      this.startDate = null;
      this.endDate = null;
      this.dialogAddTariff = false;
    },
    openConfirmAddDialog(heading, content) {
      this.confirmHeading = heading;
      this.confirmContent = content;
      this.confirmAddDialog = true;
    },
    closeConfirmAddDialog() {
      this.confirmAddDialog = false;
      this.confirmHeading = "";
      this.confirmContent = "";
    },
    openConfirmDialog(item, action, heading, message) {
      this.tariffItem = Object.assign({}, this.tariffItem, item);
      this.dialogAction = action;
      this.dialogHeading = heading;
      this.dialogMessage = message;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.tariffItem = Object.assign({}, this.tariffItem);
      this.confirmDialog = false;
      this.dialogAction = () => null;
      this.dialogHeading = "";
      this.dialogMessage = "";
    },
    closeMessage() {
      this.messageDialog = false;
    },
    prepareData(global_id, name, start_date, end_date, currency, flat, on_peak, off_peak, is_confirm) {
      const data = {
        global_id: global_id,
        name: name,
        start_date: start_date,
        end_date: end_date ? end_date : null,
        currency: currency,
        flat: flat,
        on_peak: on_peak,
        off_peak: off_peak,
        is_confirm: is_confirm,
      };
      return data;
    },
    setTariffInfo(data) {
      if (data) {
        this.editTariffName = data.name || "";
        this.editStartDate = data.start_date || null;
        this.editEndDate = data.end_date || null;
        this.editCurrency = data.currency || "";
        this.editFlatTariff = data.flat !== undefined && data.flat !== null ? data.flat : null;
        this.editOnPeakTariff = data.on_peak !== undefined && data.on_peak !== null ? data.on_peak : null;
        this.editOffPeakTariff = data.off_peak !== undefined && data.off_peak !== null ? data.off_peak : null;
        this.tariffInfo = data;
        this.changedEditStartDate();
        this.changedEditEndDate();
      } else {
        this.editTariffName = "";
        this.editStartDate = null;
        this.editStartDateText = "";
        this.editEndDate = null;
        this.editEndDateText = "";
        this.editCurrency = "";
        this.editFlatTariff = null;
        this.editOnPeakTariff = null;
        this.editOffPeakTariff = null;
        this.tariffInfo = null;
      }
    },
    openDialogEdit(item) {
      if (item?.id) {
        tariffService
          .getTariff(item.id)
          .then((res) => {
            if (res?.data?.data) {
              this.setTariffInfo(res.data.data);
              this.dialogEditTariff = true;
            }
          })
          .catch();
      }
    },
    closeDialogEdit() {
      this.setTariffInfo(null);
      this.revertTariffEdit();
      this.editStartDate = null;
      this.editEndDate = null;
      this.dialogEditTariff = false;
    },
    handleSubmitEdit() {
      if (this.$refs.editForm.validate()) {
        // const updatedAtMoment = moment(this.tariffInfo.updated_at, "YYYY-MM-DD HH:mm:ss");
        // const currentTime = moment();
        // if (currentTime.diff(updatedAtMoment, 'minutes') >= 5) {
        //   //Check after 5 minutes
        // } else {
        //   this.apiMessage = "Please wait 5 minutes before making further changes to this tariff";
        //   this.apiTitle = "Cannot Edit Tariff";
        //   this.messageDialog = true;
        // }
        const data = this.prepareData(
          this.global,
          this.editTariffName,
          this.editStartDate,
          this.editEndDate,
          this.editCurrency,
          this.editFlatTariff,
          this.editOnPeakTariff,
          this.editOffPeakTariff
        );
        tariffService
          .updateTariff(this.tariffInfo.id, data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.apiMessage = `Tariff has been successfully updated.<br>This tariff cannot be changed within the next 1 hour`;
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListTariffs(this.global);
            this.closeDialogEdit();
          })
          .catch((err) => {
            let message = "Tariff cannot be updated";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    revertTariffEdit() {
      this.$refs.editForm.reset();
    },
  },
};
</script>
